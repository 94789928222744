<style media="screen" type="text/css">
/* .drawer-icons {
  height: 14px;
  width: 14px;
}

.drawer-text {
  font-family: Nunito Sans;
  font-style: normal;
  font-size: 16px !important;
  line-height: 19px;
  align-items: center;
  color: #333333;
}

.drawer_list .v-list-item--link::before {
  background-color: transparent !important;
}

.drawer_list .v-list-item--active .drawer-text {
  color: #ffff !important;
  background-color: #194e76;
  font-weight: bold;
}

.v-list-item--active {
  border-left: 5px solid #f18324 !important;
}

.v-list-item {
  border-left: 5px solid transparent;
} */
</style>

<template>
  <v-card>
    <v-navigation-drawer
      v-model="drawer"
      app
      class="drawer-wrapper"
      permanent
      width="200"
    >
      <!--              :mini-variant.sync="mini"-->

      <div class="py-4 text-center">
        <center>
          <v-img
            class="mt-2"
            src="@/assets/images/cashpo_logo.svg"
            width="110px"
          ></v-img>
        </center>
      </div>
      <v-list class="pa-0 drawer_list py-0 drawer-list-wrapper" dense nav>
        <div v-for="(item, i) in getDrawerItems" :key="i" class="px-4">
          <v-list-item
            v-if="!item.is_django_tab && getPermissionList.includes(item.title)"
            :to="item.url"
            class="pa-0 mt-2 drawer-item"
            link
            active-class="acitve-drawer"
            @click="setActiveTab(item)"
          >
            <!-- :class="i == 0 ? 'tab-border-t' : ''" -->
            <v-list-item-content class="py-0">
              <v-list-item-title class="drawer-text py-2 pl-4">
                {{ item.title }}
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <!-- for django tabs -->
          <!-- <a
            v-if="item.is_django_tab && getPermissionList.includes(item.title)"
            :href="item.url"
            style="text-decoration: none"
            target="_blank"
          >
            <v-list-item
              :class="i == 0 ? 'tab-border-t' : ''"
              class="pa-0"
              link
            >
              <v-list-item-content class="py-0">
                <v-list-item-title class="drawer-text py-4 pl-4">
                  {{ item.title }}
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </a> -->
        </div>
      </v-list>

      <!--   login btn   -->
      <div class="pa-2 text-center mt-16 pt-16">
        <v-btn block color="error" outlined @click="logout">
          <b>{{ $lang.LOGOUT }}</b>
        </v-btn>
      </div>
    </v-navigation-drawer>
  </v-card>
</template>
<script>
import { mapGetters } from "vuex";

export default {
  data() {
    return {
      drawer: false,
      warehouse: null,
      loading: false,
      mini: false,
      new_onboarding_tabs: [
        {
          id: 1,
          title: "New Customer",
          url: { name: "new-customers" },
        },
        // {
        //   id: 2,
        //   title: "Loan Applied",
        //   url: {name: 'loan-applied'},
        // },
      ],
    };
  },
  computed: mapGetters({
    getDrawerItems: "drawer/getDrawerItems",
    getManageTab: "drawer/getManageTab",
    getPermissionList: "getPermissionList",
  }),
  created() {
    // console.log("$store.state.primary_drawer");
    // console.log(this.$store.state.drawer.primary_drawer);
    this.$store.dispatch(
      "drawer/setActiveTab",
      localStorage.getItem("active-tab")
    );
  },
  methods: {
    setActiveTab(item) {
      // console.log(item);
      this.$store.dispatch("drawer/setActiveTab", item.url.name);
      // clearing search text on tab change
      this.$store.dispatch("setSearchText", "");
    },
    logout() {
      // console.log("this si logout");
      this.request_POST(self, this.$urls.LOGOUT_TEAMMATE);
      localStorage.removeItem("active-tab");
      // sessionStorage.removeItem("token");
      // console.log("this si logout");
      sessionStorage.clear();
      this.$router.push({ name: "login" });
    },
  },
};
</script>
<style scoped>
.drawer-wrapper {
  direction: rtl;
  /* pressed */
  /* background: #e3edf7;
  height: fit-content !important;
  box-shadow: inset 6px 6px 12px #dbe1e7, inset -6px -6px 12px #ffffff;
  border: 1px solid #a9d7ff57 !important; */

  /* flat */
  background: #eef5fb !important;
  box-shadow: 6px 6px 12px #dbe1e7, -6px -6px 12px #ffffff !important;
  border: 1px solid #a9d7ff57 !important;

  /* convex */
  /* background: linear-gradient(145deg, #f3feff, #ccd5de);
  box-shadow: 6px 6px 12px #c1c9d2, -6px -6px 12px #ffffff;
  border: 1px solid #a9d7ff57 !important; */

  /* concave */
  /* border: 1px solid #a9d7ff57 !important;
  background: linear-gradient(145deg, #ccd5de, #f3feff);
  box-shadow: 6px 6px 12px #c1c9d2, -6px -20px 12px #ffffff; */
}
/* .acitve-drawer {
  background: #e3edf7 !important;
  height: fit-content !important;
  box-shadow: inset 6px 6px 12px #dbe1e7, inset -6px -6px 12px #ffffff;
  border: 1px solid #a9d7ff57 !important;
  border-radius: 10px;
} */

.drawer_list .v-list-item--active {
  /* border-radius: 10px; */
  /* color: green !important; */
  font-weight: 800 !important;

  background: #eef5fb !important;
  color: #eef5fb !important;
  /* color: #eef5fb !important;
  box-shadow: 6px 6px 12px #dbe1e7, -6px -6px 12px #ffffff !important;
  border: 1px solid #a9d7ff57 !important; */

  height: fit-content !important;
  /* box-shadow: inset 6px 6px 12px #dbe1e7, inset -6px -6px 12px #ffffff; */
  box-shadow: inset 6px 6px 12px #ff9d2652, inset -6px -6px 12px #ffffff;
  border: 1px solid #7ea5c757 !important;
  /* border-radius: 15px !important; */
}
.drawer-list-wrapper {
  direction: ltr;
}
.drawer-item {
  /* border-radius: 15px !important; */
}
.drawer-item:hover {
  /* background: #e3edf7;
  box-shadow: inset 6px 6px 12px #dbe1e7, inset -6px -6px 12px #ffffff;
  border: 1px solid #a9d7ff57 !important; */

  height: fit-content !important;
  box-shadow: inset 6px 6px 12px #dbe1e7, inset -6px -6px 12px #ffffff;
  border: 1px solid #a9d7ff57 !important;
}
.drawer-text{
  color: #333;
  font-weight: 700 !important;
}
</style>
